import axios, { AxiosError, AxiosResponse } from "axios";
import { initializeApp } from "firebase/app";
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
  getToken,
} from "firebase/app-check";
const prodUrl = "https://api.moniro.app/webhandler";
const localUrl = "http://localhost:3000/webhandler";
const stagingUrl =
  "https://moniro-backend-staging-gsixwphnia-lz.a.run.app/webhandler";
// console.log("process.env.NODE_ENV", process.env.NODE_ENV);
const baseUrl = process.env.NODE_ENV === "development" ? prodUrl : prodUrl;
// const baseUrl = process.env.NODE_ENV === "development" ? "35610d17-47f9-4fe1-b4ca-e667511fb4f2" : false;

// const baseUrl = localUrl;
const firebaseConfig = {
  apiKey: "AIzaSyAymu3kLiTUB57aiN6hqi_k2ODAPSdB3NU",
  authDomain: "prime-apricot-370212.firebaseapp.com",
  databaseURL:
    "https://prime-apricot-370212-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "prime-apricot-370212",
  storageBucket: "prime-apricot-370212.appspot.com",
  messagingSenderId: "476360637848",
  appId: "1:476360637848:web:74f3e7149d87f90978bfed",
};

const app = initializeApp(firebaseConfig);
declare global {
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}
// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
// site key and pass it to initializeAppCheck().
window.self.FIREBASE_APPCHECK_DEBUG_TOKEN =
  process.env.NODE_ENV === "development";
  // process.env.NODE_ENV === "development"?"ff778d78-4464-4576-8556-31f399607e9e":false;
// console.log("self", window.self.FIREBASE_APPCHECK_DEBUG_TOKEN);
const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    "6Ld3cJEnAAAAAFcmqzQKZGZYqAl35fvk6PU1wkVy"
  ),
  isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
});

async function gett(refresh = false) {
  return (
    (!refresh &&
      localStorage.EXPTIME &&
      parseInt(localStorage.EXPTIME) > Date.now() - 3600000 &&
      localStorage.getItem("AUTHTOKEN")) ||
    (
      await getToken(appCheck).then((appCheckTokenResponse) => {
        localStorage.setItem("AUTHTOKEN", appCheckTokenResponse.token);
        localStorage.setItem("EXPTIME", Date.now().toString());
        return appCheckTokenResponse;
      })
    ).token
  );
}

async function getRequest(
  url: string,
  refreshToken = false
): Promise<AxiosResponse> {
  return axios
    .get(url, {
      headers: { "X-Firebase-AppCheck": await gett(refreshToken) },
    })
    .catch((reason: AxiosError) => {
      if (reason?.response?.status == 401 && !refreshToken) {
        return getRequest(url, true);
      } else {
        throw reason;
      }
    });
}

async function postRequest(
  url: string, data = {},
  refreshToken = false
): Promise<AxiosResponse> {
  return axios
    .post(url, data, {
      headers: { "X-Firebase-AppCheck": await gett(refreshToken) },
    })
    .catch((reason: AxiosError) => {
      if (reason?.response?.status == 401 && !refreshToken) {
        return getRequest(url, true);
      } else {
        throw reason;
      }
    });
}

export async function getRecentPodcasts() {
  return getRequest(baseUrl + "/recentBookCircless")

    .then((response) => response.data)
    .catch((err) => console.log("getRecentPodcasts", err));
}
export async function getRecentBookclubs() {
  return getRequest(baseUrl + "/recentBookClubs")

    .then((response) => response.data)
    .catch((err) => console.log("getRecentBookclubs", err));
}
export async function getPopularBookclubs() {
  return getRequest(baseUrl + "/popularBookClubs")

    .then((response) => response.data)
    .catch((err) => console.log("getPopularBookclubs", err));
}

export async function getRecommndedBook() {
  return getRequest(baseUrl + "/moniroRecommendedBooks")

    .then((response) => response.data)
    .catch((err) => console.log("getRecommendedBook", err));
}
export async function getFeaturedFamousPersons(page: number) {
  return getRequest(baseUrl + "/moniroFeaturedFamousPersons/" + page)

    .then((response) => response.data)
    .catch((err) => console.log("FeaturedFamousPersons", err));
}
export async function getBookRecordingweb() {
  return getRequest(baseUrl + "/bookRecordingweb2")

    .then((response) => response.data)
    .catch((err) => console.log("getBookRecordingweb", err));
}
export async function getPodcast(id: string) {
  return getRequest(baseUrl + "/book-circle/" + id)

    .then((response) => response.data)
    .catch((err) => console.log("getPodcast", err));
}

export async function getBook(id: string) {
  return getRequest(baseUrl + "/bookInfo/" + id)
    .then((response) => response.data)
    .catch((err) => console.log("getBook", err));
}

export async function getRelatedBooks(id: string) {
  return getRequest(baseUrl + "/booksSuggestionsWithGenre/" + id)

    .then((response) => response.data)
    .catch((err) => console.log("getRelatedBooks", err));
}

export async function getBookClub(id: string) {
  return getRequest(baseUrl + "/getBookClub/" + id)

    .then((response) => response.data)
    .catch((err) => console.log("getBookClub", err));
}

export async function getBookClubPodcasts(id: string, page: number) {
  return getRequest(baseUrl + "/findOngoingBookCircleForBookClub/" + id + "/" + page)
    .then((response) => response.data)
    .catch((err) => console.log("getBookClub", err));
}

export async function getRTCToken(uid: string, channelid: string) {
  return getRequest(baseUrl + "/rtc/" + channelid + "/" + uid)
    .then((response) => response.data)
    .catch((err) => console.log("getRTCToken", err));
}

export async function getBookRecording(book: string, page: number) {
  return getRequest(baseUrl + "/bookRecording/" + book + "/" + page)
    .then((response) => response.data)
    .catch((err) => console.log("getBookRecording", err));
}
export async function getAuthors(page: number) {
  return getRequest(`${baseUrl}/getAuthors/${page}`)
    .then((response) => response.data)
    .catch((err) => {
      console.log("getAuthors Error:", err);
      throw err; 
    });
}

export async function getPrivacyAndPolicy() {
  return getRequest(`${baseUrl}/getsystemsettings`)
    .then((response) => response.data)
    .catch((err) => {
      console.log("get System setting Error:", err);
      throw err; 
    });
}
export async function getAllPodcastsForBook(id: string) {
  return getRequest(baseUrl + "/book-circle-for-book/" + id)

    .then((response) => response.data)
    .catch((err) => console.log("getAllPodcastsForBook", err));
}

export async function getBookclubsForBook(id: string) {
  return getRequest(baseUrl + "/bookClubsInBook/" + id)
    .then((response) => response.data)
    .catch((err) => console.log("getBookclubsForBook", err));
}

export async function getUserProfile(id: string) {
  return getRequest(baseUrl + "/userProfile/" + id)
    .then((response) => response.data)
    .catch((err) => console.log("getUserProfile", err));
}

export async function getOtherUserProfile(id: string) {
  return getRequest(baseUrl + "/otherUserProfile/" + id)
    .then((response) => response.data[0])
    .catch((err) => console.log("getOtherUserProfile", err));
}

export async function getUserBookShelf(id: string) {
  return getRequest(baseUrl + "/getUserBookShelf/" + id)
    .then((response) => response.data)
    .catch((err) => console.log("getUserBookShelf", err));
}
                    
export async function getDynamicLink(
  title: string,
  description: string,
  bookImage: string,
  bookId: string
) {
  return postRequest(
    `${baseUrl}/createDynamicLink`,
    {
      url: `https://moniro.app/book/${bookId}`,
      title: title,
      discription: description
        ? description
        : "Monro - Where book-lovers meet",
      imageUrl: bookImage
        ? bookImage
        : "https://cdn.pixabay.com/photo/2015/11/19/21/10/glasses-1052010__340.jpg",
    }
  )
    .then((response) => response.data)
    .catch((err) => console.log("getDynamicLink", err));
}

export async function searchFamousPersons(query = "", page = 1, limit = 100) {
  return getRequest(`${baseUrl}/searchfamous?q=${query}&page=${page}&limit=${limit}`)
    .then((response) => response.data)
    .catch((error) => {
      console.error("Error while searching famous persons:", error);
      // return {
      //   success: false,
      //   error: error.response?.data?.message || error.message || "An unknown error occurred",
      // };
});
}

export async function getFamousPersonsByCategory(category: string, page: number) {
  return getRequest(`${baseUrl}/getbycategory/${category}/${page}`)
    .then((response) => response.data)
    .catch((err) => console.log("getFamousPersonsByCategory", err));
}


export default baseUrl;
