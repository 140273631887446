import React, { useState, useEffect } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";
import { getAuthors, getFamousPersonsByCategory, searchFamousPersons } from "../services/api";
import { FaSearch, FaBook, FaQuoteLeft, FaQuoteRight } from "react-icons/fa";
import Footer from "../components/Footer";

const CATEGORIES = [
  "All", 
  "Author",
  "Scientist",
  "Artist",
  "Philosopher",
  "Inventor",
  "Leader",
  "Singer",
  "Journalist",
];

export default function AuthorBookTips() {
  const [modal, setModal] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [filteredAuthors, setFilteredAuthors] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showNoData, setShowNoData] = useState(false);
  // Fetch authors on initial load and when the page changes
  useEffect(() => {
    const fetchAuthors = async (page) => {
      try {
        const data = await getAuthors(page);
        console.log('data', data);
        
        setAuthors((prev) => {
          const uniqueAuthors = [
            ...prev,
            ...data.filter((newAuthor) => !prev.some((old) => old._id === newAuthor._id)),
          ];
          return uniqueAuthors;
        });

        if (!data || data.length === 0) {
          setHasMore(false);
        }
      } catch (error) {
        console.error("Failed to fetch authors:", error);
      }
    };

    if (!searchTerm) {
      fetchAuthors(currentPage);
    }

    const timer = setTimeout(() => {
      setShowNoData(true); 
    }, 10000);

    return () => clearTimeout(timer);
  }, [currentPage, searchTerm]);

  // Filter authors based on the selected category
  useEffect(() => {
    if (selectedCategory === "All") {
      setFilteredAuthors(authors);
    } else {
      const filtered = authors.filter((author) => 
        author.category && author.category.includes(selectedCategory)
      );
      setFilteredAuthors(filtered);
    }
  }, [selectedCategory, authors]);
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop + 1 >=
        document.documentElement.scrollHeight
      ) {
        if (hasMore && !searchTerm) {
          setCurrentPage((prev) => prev + 1);
        }
      }
    };
  
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore, searchTerm]);
  // Fetch search results when the user searches
  useEffect(() => {
    const fetchSearchResults = async () => {
      if (searchTerm) {
        try {
          const data = await searchFamousPersons(searchTerm);
          setSearchResults(data.data);
        } catch (error) {
          console.error("Failed to fetch search results:", error);
          setSearchResults([]);
        }
      } else {
        setSearchResults([]);
      }
    };

    fetchSearchResults();
  }, [searchTerm]);

  const handleCategoryClick = async (category) => {
    setSelectedCategory(category);
    setCurrentPage(1); 
    setHasMore(true);
  
    if (category === "All") {
      setAuthors([]);
      setFilteredAuthors([]);
      try {
        const data = await getAuthors(1); 
        setAuthors(data);
        setFilteredAuthors(data);
      } catch (error) {
        console.error("Failed to reload authors:", error);
      }
      return;
    }
  
    try {
      const data = await getFamousPersonsByCategory(category, 1);
      setAuthors(data.filter((author) => 
        author.category && author.category.includes(category)
      ));
      setFilteredAuthors(data.filter((author) => 
        author.category && author.category.includes(category)
      ));
    } catch (error) {
      console.error("Failed to fetch data for category:", error);
    }
  };
  
  

  const handleLoadMore = () => {
    setCurrentPage((prev) => prev + 1);
  };

  return (
    <>
      <Header setModal={setModal} modal={modal} />
      <div className="relative min-h-screen pt-3 scroll-smooth bg-dark-blue">
        <div className="max-w-7xl mx-auto px-4 py-6">
          <header className="text-white text-3xl font-bold mb-5 mt-8">
            Discover book tips from people you admire
          </header>

          {/* Search Input */}
          <div className="flex justify-end mb-6">
            <div className="relative w-full md:w-1/3">
              <FaSearch className="absolute text-gray-400 left-3 top-3" />
              <input
              type="text"
              placeholder="Search Authors"
              style={{ color: "black" }}
              className="w-full pl-10 pr-4 py-2 rounded-lg bg-navy text-white placeholder-gray-300"
              onChange={(e) => {
                const value = e.target.value;
                if (value.length >= 3 || value.length === 0) { 
                  setSearchTerm(value);
                }
              }}
              />
            </div>
          </div>

          {/* Categories */}
          {searchTerm? " ": (
          <div className="mb-6 overflow-x-auto scrollbar-hide">
            <div className="flex space-x-4 px-4 py-2 bg-opacity-50 backdrop-blur-md">
              {CATEGORIES.map((category) => (
                <button
                  key={category}
                  // onClick={() => setSelectedCategory(category)}
                  onClick={() => handleCategoryClick(category)}
                  className={`px-4 py-2 rounded-full border-2 ${selectedCategory === category
                      ? "bg-gold text-white font-bold border-gold"
                      : "text-white border-gold"
                    }`}
                >
                  {category}
                </button>
              ))}
            </div>
          </div>
          )}



          {/* Conditional rendering for search results or filtered authors */}
          {searchTerm ? (
            searchResults.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {searchResults.map((author) => (
                  <div
                    key={author._id}
                    className="bg-navy rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300 border border-light"
                  >
                    <div className="p-4">
                      <div className="flex items-center space-x-4">
                        <img
                          className="h-20 w-20 rounded-full object-cover"
                          src={
                            author.imageUrl ||
                            "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                          }
                          alt={author.famousPersonName}
                        />
                        <div className="flex-1">
                          <Link
                            to={`/web/${author._id}/${author.famousPersonName.replace(
                              /\s+/g,
                              "-"
                            )}`}
                            className="text-white text-xl font-semibold hover:underline"
                          >
                            {author.famousPersonName}
                          </Link>
                          <div className="text-white text-sm opacity-65 flex items-center">
                            <FaQuoteLeft className="mr-1" />
                            <span>
                              {author.quote.length > 50
                                ? `${author.quote.substring(0, 40)}...`
                                : author.quote}
                            </span>
                            <FaQuoteRight className="ml-1" />
                          </div>
                          
                        </div>
                        <Link
                          to={`/web/${author._id}/${author.famousPersonName.replace(
                            /\s+/g,
                            "-"
                          )}`}
                          className="flex items-center text-white font-bold text-lg hover:underline"
                        >
                          <FaBook className="mr-2" />
                          {author.books ? author.books.length : 0}
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="flex flex-col items-center justify-center min-h-screen bg-dark-blue text-white">
      {showNoData ? (
        <p className="text-lg font-semibold">No data found</p>
      ) : (
        <>
          <p className="text-lg font-semibold mb-4">
            Please wait while we fetch the data...
          </p>
          <div className="flex space-x-2">
            <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-100"></div>
            <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-200"></div>
            <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-300"></div>
          </div>
        </>
      )}
    </div>
            )
          ) : filteredAuthors.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {filteredAuthors.map((author) => (
                <div
                  key={author._id}
                  className="bg-navy rounded-lg overflow-hidden shadow-lg hover:shadow-2xl transition-shadow duration-300 border border-light"
                >
                  <div className="p-4">
                    <div className="flex items-center space-x-4">
                      <img
                        className="h-20 w-20 rounded-full object-cover"
                        src={
                          author.imageUrl ||
                          "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                        }
                        alt={author.famousPersonName}
                      />
                      <div className="flex-1">
                        <Link
                          to={`/web/${author._id}/${author.famousPersonName.replace(
                            /\s+/g,
                            "-"
                          )}`}
                          className="text-white text-xl font-semibold hover:underline"
                        >
                          {author.famousPersonName}
                        </Link>
                        <div className="text-white text-sm opacity-65 flex items-center">
                          <FaQuoteLeft className="mr-1" />
                          <span>
                            {author.quote.length > 50
                              ? `${author.quote.substring(0, 40)}...`
                              : author.quote}
                          </span>
                          <FaQuoteRight className="ml-1" />
                        </div>
                        
                      </div>
                      <Link
                          to={`/web/${author._id}/${author.famousPersonName.replace(
                            /\s+/g,
                            "-"
                          )}`}
                          className="flex items-center text-white font-bold text-lg hover:underline"
                        >
                          <FaBook className="mr-2" />
                          {author.books ? author.books.length : 0}
                        </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="flex flex-col items-center justify-center min-h-screen bg-dark-blue text-white">
      {showNoData ? (
        <p className="text-lg font-semibold">No data found</p>
      ) : (
        <>
          <p className="text-lg font-semibold mb-4">
            Please wait while we fetch the data...
          </p>
          <div className="flex space-x-2">
            <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-100"></div>
            <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-200"></div>
            <div className="h-3 w-3 bg-yellow-500 rounded-full animate-bounce delay-300"></div>
          </div>
        </>
      )}
    </div>
          )}

          {/* {!searchTerm && hasMore && (
            <div className="flex justify-center mt-6">
              <button
                onClick={handleLoadMore}
                className="bg-orange1 text-white px-6 py-2 rounded-lg font-bold"
              >
                Load More
              </button>
            </div>
          )} */}
        </div>
      </div>
      <Footer />
    </>
  );
}
